import { useState, useEffect, useRef, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
// import '../css/stop-service.css';
import "../css/appointmentSample.css";
import "../css/appointmentCalendar.css";
import "../css/cso.css";
import spinnerContext from "../context/spinnerContext";

function getWeekdayFirstHalf(ascheduleDate) {
  const STR_FIRST_HALF_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const aDate = new Date(ascheduleDate);
  const dayOfWeekIndex = aDate.getDay();
  return STR_FIRST_HALF_DAYS[dayOfWeekIndex];
}

function getWeekdaySecondHalf(ascheduleDate) {
  const STR_LAST_HALF_DAYS = [
    "day",
    "day",
    "sday",
    "nesday",
    "rsday",
    "day",
    "urday",
  ];
  const aDate = new Date(ascheduleDate);
  const dayOfWeekIndex = aDate.getDay();
  return STR_LAST_HALF_DAYS[dayOfWeekIndex];
}

function getMonthAndDate(ascheduleDate) {
  const STR_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const aDate = new Date(ascheduleDate);
  const monthIndex = aDate.getMonth();
  const monthName = STR_MONTHS[monthIndex];
  const dayOfMonth = aDate.getDate();
  return `, ${monthName} ${dayOfMonth}`;
}

function FreeAppointmentCalendar({
  selectedAppointment,
  setSelectedAppointments,
  a2FoodIndustry = "", csoAvailabilitySchedArr, props
}) {
  // console.log("cso data ", csoAvailabilitySchedArr);

  const [selectedSlot, setSelectedSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const captureSelectedDate = (slot, dateString) => {


    let cellPhoneNumber;
    let appointmentType = "";
    let termsAndCondition =selectedAppointment.termsAndCondition;

    switch (slot) {
      case "1":
        appointmentType = "AM";
        break;
      case "2":
        appointmentType = "PM";
        break;
      case "3":
        appointmentType = "Evening";
        break;
      case "N":
        appointmentType = "All Day";
        break;
      default:
        appointmentType = "Unknown";
    }

    const createdTimeStamp = new Date().toISOString().split("T")[0];
    const scheduleDate = `${dateString.split('-')[1]}-${dateString.split('-')[2]}-${dateString.split('-')[0]}`

    const appointmentDetails = {
      tokenId: props.serviceInfo.tokenId,
      slot,
      serviceType: "UPDATECSOSCHEDULE",
      appointmentType,
      createdTimeStamp,
      scheduleDate: scheduleDate,
      cellPhoneNumber,
      termsAndCondition
    };

    setSelectedSlot(slot);
    setSelectedDate(scheduleDate);
    setSelectedAppointments(appointmentDetails);


  };

  const wrappedLeftArrowClickCB = (e) => {
    e.preventDefault();
    window["leftArrowClickCB"]();
  };

  const wrappedRightArrowClickCB = (e) => {
    e.preventDefault();
    window["rightArrowClickCB"]();
  };
  
  return (
    <div
      id="apt-calendar"
      className="apt-calendar-widget apt-calendar"
      role="application"
      aria-live="polite"
    >
      <table style={{ borderCollapse: "collapse" }}>
        <tbody>
          <tr>
            <td>
              <a
                className="apt-calendar-left-arrow"
                onClick={wrappedLeftArrowClickCB}
              >
                <span className="arrow-left display-none"></span>
              </a>
            </td>
            <td className="apt-calendar-dates-cell" valign="top">
              <div className="apt-calendar-dates-container">
                <table
                  role="grid"
                  cellPadding="0"
                  cellSpacing="0"
                  className="apt-calendar-dates-table"
                  style={{ left: 0, height: "98%" }}
                >
                  <colgroup>
                    {csoAvailabilitySchedArr.map((d) => (
                      <col key={d.s100_schd_day_dt} style={{ width: 102 }} />
                    ))}
                  </colgroup>
                  <thead>
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((d) => (
                        <th key={d.s100_schd_day_dt} role="columnheader">
                          {d.s100_schd_day_dt ? (
                            <>
                              {getWeekdayFirstHalf(d.s100_schd_day_dt)}
                              <span className="spokenOnly">
                                {getWeekdaySecondHalf(d.s100_schd_day_dt)}
                              </span>
                              {getMonthAndDate(d.s100_schd_day_dt)}
                            </>
                          ) : (
                            <span className="dnf" >N/A</span>
                          )}
                        </th>
                      ))}
                    </tr>

                  </thead>
                  <tbody>
                    {/* All Day slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.s100_schd_day_dt}
                          role="gridcell"
                          className={
                            v.s100_schd_day_dt === selectedDate && selectedSlot === "N"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectAllDay facet */}
                          {(v.s100_avail_all_day === "Y" ||
                            (a2FoodIndustry === "4hour" && i === 0)) && (
                              <a
                                onClick={() =>
                                  captureSelectedDate("N", v.s100_schd_day_dt)
                                }
                              >
                                {a2FoodIndustry === "4hour" && i === 0 && (
                                  <span>Within 4 Hours</span>
                                )}
                                {(a2FoodIndustry !== "4hour" || i !== 0) && (
                                  <span>
                                    All day:
                                    <br />7 am - 8 pm
                                  </span>
                                )}
                              </a>
                            )}

                          {/* ADNotApplicable facet */}
                          {v.s100_avail_all_day === "N" &&
                            !(a2FoodIndustry === "4hour" && i === 0) && (
                              <span>
                                <span className="fs-14">N/A</span>
                                <span className="spokenOnly">
                                  {getWeekdayFirstHalf(v.s100_schd_day_dt) +
                                    getWeekdaySecondHalf(v.s100_schd_day_dt) +
                                    getMonthAndDate(v.s100_schd_day_dt) +
                                    " is not available."}
                                </span>
                              </span>
                            )}
                        </td>
                      ))}
                    </tr>
                    {/* AM slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.s100_schd_day_dt}
                          role="gridcell"
                          className={
                            v.s100_schd_day_dt === selectedDate && selectedSlot === "1"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* select7AM facet */}
                          {v.s100_avail_am === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("1", v.s100_schd_day_dt)
                              }
                            >
                              7 am - Noon
                            </a>
                          )}
                          {/* 7AMNotApplicable facet */}
                          {v.s100_avail_am === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.s100_schd_day_dt) +
                                  getWeekdaySecondHalf(v.s100_schd_day_dt) +
                                  getMonthAndDate(v.s100_schd_day_dt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                    {/* PM slot */}
                    <tr role="row">
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.s100_schd_day_dt}
                          role="gridcell"
                          className={
                            v.s100_schd_day_dt === selectedDate && selectedSlot === "2"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectPM facet*/}
                          {v.s100_avail_pm === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("2", v.s100_schd_day_dt)
                              }
                            >
                              Noon - 5 pm
                            </a>
                          )}
                          {/* PMNotApplicable facet */}
                          {v.s100_avail_pm === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.s100_schd_day_dt) +
                                  getWeekdaySecondHalf(v.s100_schd_day_dt) +
                                  getMonthAndDate(v.s100_schd_day_dt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                    {/* Evening slot */}
                    <tr
                      role="row"
                      style={
                        a2FoodIndustry === "hideNARow" ||
                          a2FoodIndustry === "4hour"
                          ? { display: "none" }
                          : {}
                      }
                    >
                      {csoAvailabilitySchedArr.map((v, i) => (
                        <td
                          key={v.s100_schd_day_dt}
                          role="gridcell"
                          className={
                            v.s100_schd_day_dt === selectedDate && selectedSlot === "3"
                              ? "selected-apt-date"
                              : ""
                          }
                        >
                          {/* selectEvng facet */}
                          {v.s100_avail_evening_wnd === "Y" && (
                            <a
                              onClick={() =>
                                captureSelectedDate("3", v.s100_schd_day_dt)
                              }
                            >
                              5 pm - 8 pm
                            </a>
                          )}
                          {/* EvngNotApplicable facet */}
                          {v.s100_avail_evening_wnd === "N" && (
                            <span>
                              <span className="fs-14">N/A</span>
                              <span className="spokenOnly">
                                {getWeekdayFirstHalf(v.s100_schd_day_dt) +
                                  getWeekdaySecondHalf(v.s100_schd_day_dt) +
                                  getMonthAndDate(v.s100_schd_day_dt) +
                                  " is not available."}
                              </span>
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
            <td>
              <a
                className="apt-calendar-right-arrow"
                onClick={wrappedRightArrowClickCB}
              >
                <span className="arrow-right display-none"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function SuccessPage() {
  return (
    <section
      id="success_message_section"
      className="success-page content-separator d-flex justify-content-center align-items-center  p-4 m-3"
      style={{ background: "white", height: "34rem" }}
    >
      <div className="message-container">
        <div className="d-flex justify-content-center align-items-center">
          <img
            title="Success"
            alt="Success"
            className="message-icon"
            src="/icon_green_checkmark.png"
          />
        </div>
        <div className="success-text-panel d-flex flex-column justify-content-center align-items-center">
          <h1 className="header-text">Success!</h1>
          <p id="success_desc_text">
            We have received your forwarding address. Thank you!
          </p>
        </div>
      </div>
    </section>
  );
}

function ErrorPage() {
  return (
    <section
      id="backend_error_message_section"
      className="content-separator"
    >
      <div className="message-container">
        <img
          title="Error"
          alt="Error"
          className="message-icon"
          src={"/icon_red_attention.png"}
        />
        <p>
          We are unable to process your request at this time. Please stay on the
          call and we will continue through the automated phone system.
        </p>
      </div>
    </section>
  );
}

function App(props) {
  const showSpinner = useContext(spinnerContext);
  const [bufferStaus, setBufferStatus] = useState("Looking up available appointments...")
  const [csoAvailabilitySchedArr, setcsoAvailabilitySchedArr] = useState([])
  const formURL = process.env.REACT_APP_START_SERVICE_SUBMIT_API;
  const navigate = useNavigate();
  const [selectedAppointment, setSelectedAppointment] = useState({
    slot: "",
    serviceType: "",
    appointmentType: "",
    createdTimeStamp: "",
    scheduleDate: "",
    cellPhoneNumber: "",
    termsAndCondition:""
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const errorSectionRef = useRef(null);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [appointmentPage, setShowAppointmentPage] = useState(true);
  const [showErrorPage, setShowErrorPage] = useState(false);
  window.addEventListener("beforeunload", function (e) {
    var confirmationMessage = "o/";

    (e || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  });
  const hasFetchedData = useRef(false);

  useEffect(() => {
   
    console.log("props ", props);

    if (hasFetchedData.current) return; // Prevent second call
    hasFetchedData.current = true
    const postData = async () => {
      const payload = {
        tokenId: props.serviceInfo.tokenId,
        //  tokenId:"2914efbb-0a65-4264-aa6f-f148b7ff4bee",
        serviceType: "",
      };

      try {
        const response = await fetch(formURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
         
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        console.log("result:-", result);

        if (result.message.length > 0 && result.message != "Error") {
        
          let parsedMessage = [];
          try {
            parsedMessage = JSON.parse(result.message.replace(/'/g, '"'));
          } catch (error) {
       
            console.error("Error parsing result.message:", error);
          }
          if (parsedMessage.length > 0) {
        
            setcsoAvailabilitySchedArr(parsedMessage);
            console.log("Updated csoAvailabilitySchedArr:", parsedMessage);
          } else {
            setBufferStatus("No Record Found")
            console.warn("Parsed message is empty or invalid.");
          }

        } else {
          setShowAppointmentPage(false);
          setShowSuccessPage(false);
          setShowErrorPage(true);
          //setBufferStatus("No Record Found")
        }
      } catch (error) {
        setBufferStatus("An error has occurred.")
        console.error("Error:", error.message);
      }
    };

    postData();

    setTimeout(function () {
      window["apptCalMultiInit"]();
    }, 2000);
  }, []); // Empty dependency array ensures it runs only once


  const getSelectedAppointmentDisplay = () => {
    const STR_DAYS = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const STR_MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const { scheduleDate, slot } = selectedAppointment;

    // Parse the date from "dd-mm-yyyy" format
    const [month, day, year] = scheduleDate.split('-');
    const aDate = new Date(`${year}-${month}-${day}`);

    const dayOfWeek = STR_DAYS[aDate.getDay()];
    const shortMonthName = STR_MONTHS[aDate.getMonth()];
    const dayOfMonth = aDate.getDate();
    const yearDisplay = aDate.getFullYear();

    let slotDesc = "";
    if (slot === "1") {
      slotDesc = "7 am - Noon";
    } else if (slot === "2") {
      slotDesc = "Noon - 5 pm";
    } else if (slot === "3") {
      slotDesc = "5 pm - 8 pm";
    } else if (slot === "4") {
      slotDesc = "Within 4 Hours";
    } else {
      slotDesc = "7 am - 8 pm";
    }

    return `${dayOfWeek}, ${shortMonthName} ${dayOfMonth}, ${yearDisplay}, ${slotDesc}`;
  };


  function handleChange(event) {
    let { name, type, value, checked } = event.target;

    // If it's a checkbox, use checked value
    if (type === "checkbox") {
      value = checked;
    }

    // If the field is "cellPhoneNumber", ensure only 10 digits
    if (name === "cellPhoneNumber") {
      if (!/^\d{0,10}$/.test(value)) return; // Prevent invalid input
    }

    setSelectedAppointment((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = async (e) => {
    showSpinner(true);
    e.preventDefault();

    // Reset error list and hide error section initially
    setErrorMessages([]);
    errorSectionRef.current.classList.add("d-none");

    const errors = [];

    // Validation checks
    if (!selectedAppointment.scheduleDate) {
      showSpinner(false);
      errors.push("Please select a schedule date.");
    }
    // if (selectedAppointment.cellPhoneNumber != undefined && !(selectedAppointment.cellPhoneNumber.length == 10)) {
    //   showSpinner(false);
    //   console.log("selected apoint", selectedAppointment.cellPhoneNumber.length);

    //   errors.push(
    //     "Please provide a valid cellphone number."
    //   );

    // }

    // If there are validation errors, show the error section
    if (errors.length > 0) {
      showSpinner(false);
      setErrorMessages(errors);
      errorSectionRef.current.classList.remove("d-none");
      return; // Stop further execution
    }

    // Proceed with form submission if validation passes
    console.log(`Submitting data: ${JSON.stringify(selectedAppointment)}`);

    try {
      const response = await fetch(formURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedAppointment),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response:", data);
        if (data.message === "Success") {
          setShowAppointmentPage(false);
          showSpinner(false);
          setShowSuccessPage(true);
          setShowErrorPage(false);
        } else {
          showSpinner(false);
          setShowAppointmentPage(false);
          setShowSuccessPage(false);
          setShowErrorPage(true);
        }

      } else {
        showSpinner(false);
        console.error("Error in API call:", response.status, response.statusText);
      }
    } catch (error) {
      showSpinner(false);
      console.error("Error:", error);
    }
  };



  //const handleSubmit = () => location.href="https://www.tutorix.com"

  return (
    <>
      <main
        className={`d-flex flex-column main ${appointmentPage ? "d-block" : "d-none"
          }`}
      >
        <section
          id="error_section"
          className="content-wrapper content-separator d-none"
          ref={errorSectionRef}
        >
          <div className="error-list-container message-container">
            <img
              title="Error"
              alt="Error"
              className="message-icon"
              src="/icon_red_attention.png"
            />
            <div className="error-text-panel">
              <h1 className="header-text">Please fix the following errors:</h1>

              <ul id="error_list">
                {errorMessages.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section className="main__content-wrapper content-separator">

          <section className="main__content">
            <section className=" flex-grow-0 main__page-title ">
              <p className="main__page-title__name">
                Schedule A Service Appointment
              </p>
              <p className="" style={{fontSize:"20px",color:"green"}}>
                Select Date
              </p>
            </section>
            <p className="line-seperator"></p>
            <section className="flex-grow-0 user-detail">
              <form
                id="calendar_form"
                name="calendar_form"
                className="custom-form"
                action="here_you_go.action"
              >
                {/* <Availability/> */}

                {
                  (Array.isArray(csoAvailabilitySchedArr) && csoAvailabilitySchedArr.length) > 0 ? (
                    <FreeAppointmentCalendar
                    selectedAppointment={selectedAppointment}
                      setSelectedAppointments={setSelectedAppointment}
                      csoAvailabilitySchedArr={csoAvailabilitySchedArr}
                      props={props}
                    />
                  ) : (
                    <div
                      className="apt-calendar-widget apt-calendar"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "25vh" // or the desired height
                      }}
                    >
                      {bufferStaus}
                    </div>

                  )
                }

                {/* Selected Appointment */}

                <div className= {`selected-appointment-date ${(Array.isArray(csoAvailabilitySchedArr) && csoAvailabilitySchedArr.length) > 0 ? "d-block" : "d-none"}`}>
                  <label className="selected-appointment-date__label">
                    Your selected appointment is:
                  </label>
                  <span className="selected-appointment-date__text">
                    {selectedAppointment.scheduleDate &&
                      selectedAppointment.slot && (
                        <span className="selAppt">
                          &nbsp;&nbsp;
                          {getSelectedAppointmentDisplay()}
                        </span>
                      )}
                  </span>
                </div>

                <p className="line-seperator"></p>

                {/* CellPhone Number */}

                {/* <section>
                  <section className="cell-phone-number row">
                    <label
                      htmlFor="in_care_of"
                      className="cell-phone-number__label col-12 text-start label col-form-label"
                    >
                      <span style={{ color: "red" }}>*</span>Cellphone Number:
                    </label>

                    <span className="cell-phone-number__input col-12">
                      <input
                        name="cellPhoneNumber"
                        id="in_care_of"
                        className="form-input country-name-text"
                        type="text"
                        onChange={handleChange}
                        pattern="\d{10}"
                        value={selectedAppointment.cellPhoneNumber}
                      />
                    </span>
                  </section>

                  <p className="gray-bg p-2 cell-phone-number__hint country-name-hint">
                    Please use the following format XXX-XXX-XXXX The cellphone
                    number you provide must belong to the account holder.
                  </p>
                </section> */}

                {/* Hint */}
                {/* <div className="d-flex gap-2 p-2 cellphone-number-info"> */}
                  {/* <img
                    className="cellphone-number-info__img"
                    alt="info"
                    src="/pngwing.com.png"
                  /> */}
                  {/* <InfoRoundedIcon style={{ color: "#004B90" }} /> */}
                  {/* <p className="mb-0">
                    Your account cellphone number can be used to receive
                    important account information and alerts, such as service
                    appointments and reminders. We do not sell or distribute
                    your information to third parties.
                  </p> */}
                {/* </div> */}

                {/* Terms and Condition */}

                {/* <div className="d-flex gap-2 mb-3 terms-and-condition"> */}
                  {/* <input
                    className="terms-and-condition__checkBox "
                    type="checkbox"
                    name="termsAndCondition"
                    value="True"
                    id="flexCheckDefault"
                    onChange={handleChange}

                    checked={selectedAppointment.termsAndCondition}
                  /> */}
                  {/* <InfoRoundedIcon style={{ color: "#004B90" }} /> */}

                  {/* <label
                    className="terms-and-condition__label  form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <b>I decline to provide a cellphone number at this time.</b>{" "}
                    I understand that I will have to contact Customer Service to
                    reset my password.
                  </label> */}
                {/* </div> */}

                {/* <p className="line-seperator"></p> */}
                {/* {<span>{JSON.stringify(selectedAppointment) }</span> } */}
                {/* <span>test : {(selectedAppointment.scheduleDate != "" && (selectedAppointment.cellPhoneNumber != "" || selectedAppointment.termsAndCondition != "")) ? false : true}</span> */}


                <div className={`submit-btn d-flex flex-row-reverse mt-2 ${(Array.isArray(csoAvailabilitySchedArr) && csoAvailabilitySchedArr.length) > 0 ? "d-block" : "d-none"}`}>

                  <button
                    type="button"
                    id="next_button"
                    className="btn-orange"
                    // disabled={
                    //   !(selectedAppointment.scheduleDate != "" && (selectedAppointment.cellPhoneNumber != "" || selectedAppointment.termsAndCondition != false))
                    // }
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                <input type="hidden" id="tokenId" name="tokenId" />
              </form>
            </section>
          </section>
        </section>
      </main>

      {/* SUCCESS PAGE */}
      <main className={`main ${showSuccessPage ? "d-block" : "d-none"}`}>
        <SuccessPage />
      </main>
      <main className={`main ${showErrorPage ? "d-block" : "d-none"}`}>
        <ErrorPage />
      </main>
    </>
  );
}

export default App;
