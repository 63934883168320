import Service from "./Service";

import ScheduleAppoinment from "./Appointment";
// import StartService from "./startService";
import { useSearchParams, useParams } from "react-router-dom";

const ServiceConfig = (props) => {        
    /*const [searchParams] = useSearchParams();
    const serviceType = searchParams.get('serviceType')
    const startServiceInfo = {
        title: 'Start Service',
        hideInputfields:false,
        disabledDropdownElement:true
    }
    
    const stopServiceInfo = {
        title: 'Stop Service',
        hideInputfields:true,
        disabledDropdownElement:false
    }

    return (
        <>
            {serviceType === 'stopService' && <Service serviceInfo={stopServiceInfo} />}
            {serviceType === 'startService' && <Service  serviceInfo={startServiceInfo} />}
            {serviceType === 'scheduleAppointment' && <ScheduleAppoinment />}
        </>
    )*/


    const { serviceType, tokenId } = useParams(); // Get the dynamic part of the route
    const startServiceInfo = {
        pageTitle: "Enter Forwarding Address | SoCalGas",
        title: 'Start Service',
        tokenId,
        hideInputfields:false,
        disabledDropdownElement:true
    }
    
    const stopServiceInfo = {
        pageTitle: "Enter Forwarding Address | SoCalGas",
        title: 'Stop Service',
        tokenId,
        hideInputfields:true,
        disabledDropdownElement:false
    }
    return (
        <>
            {serviceType === 'stop' && <Service serviceInfo={stopServiceInfo} />}
            {serviceType === 'start' && <Service  serviceInfo={startServiceInfo} />}
            {serviceType === 'cso' && <ScheduleAppoinment serviceInfo={startServiceInfo}/>}
        </>
    )

}

export default ServiceConfig

